import Uscita from "./Uscita";
import useFilter from "../../../hooks/useFilter";
import { useMemo } from "react";
import { filterArray } from "../../../utilities/generali";
import Select from "../../../components/forms/Select";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import usePagination from "../../../hooks/usePagination";

const UsciteList = ({ uscite = [] }) => {
  console.log(uscite);
  const [ricetta, selectRicettaProps, clearRicetta] = useFilter(
    uscite,
    ["ricetta"],
    "Ricetta"
  );

  const [cliente, selectClienteProps, clearCliente] = useFilter(
    uscite,
    ["cliente"],
    "Cliente"
  );

  function sliceAnno(data) {
    return data.slice(0, 4);
  }
  const [anno, selectAnnoProps, clearAnno] = useFilter(
    uscite,
    ["data"],
    "Anno",
    sliceAnno
  );

  const usciteToDisplay = useMemo(() => {
    let filtered = uscite;
    if (anno) {
      filtered = filterArray(uscite, anno, ["data"]);
    }

    if (cliente) {
      filtered = filtered.filter((item) =>
        item?.cliente?.nome.includes(cliente)
      );
    }

    if (ricetta) {
      filtered = filtered.filter((item) =>
        item?.ricetta?.nome.includes(ricetta)
      );
    }

    return filtered;
  }, [anno, cliente, ricetta, uscite]);

  const [pagination, currentPage, maxItems, selectMaxItems] =
    usePagination(usciteToDisplay);

  const clearFilters = () => {
    clearRicetta();
    clearCliente();
    clearAnno();
  };

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
        <div className="md:col-span-4 text-md font-medium leading-6 text-gray-900">
          <h4>Filtra Per</h4>
        </div>
        <div>
          <Select {...selectAnnoProps} />
        </div>
        <div>
          <Select {...selectClienteProps} />
        </div>
        <div>
          <Select {...selectRicettaProps} />
        </div>
        <div className="block md:ml-auto my-auto">
          <PrimaryButton callback={clearFilters} className="w-full md:w-auto">
            Rimuovi Filtri
          </PrimaryButton>
        </div>
      </div>
      <ul
        role="list"
        className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
      >
        {usciteToDisplay.map(
          (uscita, index) =>
            index >= (currentPage - 1) * maxItems &&
            index < currentPage * maxItems && (
              <Uscita uscita={uscita} key={uscita.id} path="" />
            )
        )}
      </ul>
      {pagination}

      <div className="flex">
        <div>{selectMaxItems}</div>
      </div>
    </div>
  );
};

export default UsciteList;
