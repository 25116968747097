import usePresignedUrl from "../../../hooks/usePresignedUrl";
import { DocumentArrowDownIcon } from "@heroicons/react/24/solid";

export default function DownloadButton({ path }) {
  const { url, refresh } = usePresignedUrl(path);

  const handleDownload = async () => {
    await refresh();

    if (!url) return;
    window.open(url, "_blank").focus();
  };

  return (
    <span className="mt-auto cursor-pointer text-casalucci">
      <DocumentArrowDownIcon
        className="h-5 w-5"
        onClick={() => handleDownload()}
      />
    </span>
  );
}
