import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { setCredentials, logOut } from "../../features/auth/authSlice";

const apiRoute = "https://api.casaluccisrl.com/api/v1";
const devApiRoute = "http://localhost:8000/api/v1";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NODE_ENV === "development" ? devApiRoute : apiRoute,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    headers.set("accept", "application/json");

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
  tagTypes: [
    "Cantieri",
    "Impianti",
    "Risorse",
    "Entrate",
    "Uscite",
    "Movimenti",
  ],
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    //console.log("sending refresh token");

    //send refresh token to get new access token
    const refreshResult = await baseQuery("/auth/refresh", api, extraOptions);
    //console.log(refreshResult);

    if (refreshResult?.data) {
      const user = api.getState().auth.user;

      //store new token
      api.dispatch(
        setCredentials({ access_token: refreshResult.data.access_token, user })
      );

      //console.log("token stored", refreshResult.data.access_token);

      //retry original query with new token
      result = await baseQuery(args, api, extraOptions);
    } else {
      //console.log("erorr");
      api.dispatch(logOut);
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
