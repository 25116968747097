import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import LoadingScreen from "./LoadingScreen";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();
  const location = useLocation();
  const isRefreshing = useRef(false); // ✅ Evita refresh multipli

  useEffect(() => {
    const verifyRefreshToken = async () => {
      if (isRefreshing.current) return; // ✅ Se già in corso, evita chiamate duplicate
      isRefreshing.current = true;

      try {
        if (!auth?.tokenExpires || isNaN(auth.tokenExpires)) {
          console.warn(
            "Token expiration is missing or invalid, attempting refresh..."
          );
          await refresh();
        } else if (auth.tokenExpires <= Date.now()) {
          console.info("Token expired, attempting refresh...");
          await refresh();
        }
      } catch (error) {
        console.error("Errore nel refresh del token:", error);
      } finally {
        isRefreshing.current = false;
        setIsLoading(false); // ✅ Termina il caricamento indipendentemente dal risultato
      }
    };

    if (persist) {
      verifyRefreshToken();
    } else {
      setIsLoading(false);
    }
  }, [auth, persist, refresh]);

  if (!persist) return <Outlet />;
  if (isLoading) return <LoadingScreen />;
  if (!auth?.token)
    return <Navigate to="/login" state={{ from: location }} replace />;

  return <Outlet />;
};

export default PersistLogin;
