import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectImpiantoById, useGetImpiantiQuery } from "./impiantiApiSlice";

import { useGetEntrateQuery } from "./entrateApiSlice";
import { useGetUsciteQuery } from "./usciteApiSlice";
import { ChevronDownIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { classNames } from "../../utilities/generali";
import { ArrowPathIcon, Cog6ToothIcon } from "@heroicons/react/20/solid";
import UltimiMovimenti from "./components/UltimiMovimenti";
import Select from "../../components/forms/Select";
import RisorseList from "./components/RisorseList";
import RicetteList from "./components/RicetteList";
import useNavigation from "../../hooks/useNavigation";
import ImpiantoSkeleton from "./components/skeletons/ImpiantoSkeleton";
import RicetteRisorseSkeleton from "./components/skeletons/RicetteRisorseSkeleton";
import { selectCurrentUser } from "../auth/authSlice";

const maxMovimentiOptions = ["3", "5", "8", "10"].map((n, index) => ({
  name: n,
  id: index + 1,
}));

const Impianto = () => {
  const { impiantoId } = useParams();

  const { isLoading } = useGetImpiantiQuery();

  const selector = useMemo(
    () => (state) => selectImpiantoById(state, Number(impiantoId)),
    [impiantoId]
  );

  const impianto = useSelector(selector);
  const user = useSelector(selectCurrentUser);

  useNavigation([{ name: impiantoId, newName: impianto?.nome }]);

  const {} = useGetEntrateQuery(Number(impiantoId));
  const {} = useGetUsciteQuery(Number(impiantoId));

  const [maxMovimenti, setMaxMovimenti] = useState(maxMovimentiOptions[1]);

  //console.log(impianto);

  let content = (
    <>
      <div className="lg:flex lg:items-center lg:justify-between max-w-3xl lg:max-w-7xl mx-auto sm:px-6">
        {isLoading ? (
          <ImpiantoSkeleton />
        ) : (
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              <span>{impianto?.nome}</span>
            </h2>
            <div className="flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <Cog6ToothIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {impianto?.tipo}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <MapPinIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {impianto?.indirizzo}
              </div>
              {Boolean(impianto?.rifiuti) && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <ArrowPathIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Riciclaggio
                </div>
              )}
            </div>
          </div>
        )}
        <div className="mt-5 flex flex-col md:flex-row lg:mt-0 lg:ml-4 space-y-2 md:space-y-0">
          {/* MENU MOVIMENTI */}
          <Menu as="div" className="ml-3 relative">
            <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400 w-full">
              Movimenti
              <ChevronDownIcon
                className="-mr-1 ml-auto md:ml-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                {Boolean(impianto?.rifiuti) && (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="entrate/scarico"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Nuovo Scarico
                      </Link>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="entrate/nuova"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Nuova Entrata
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="uscite/nuova"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Nuova Uscita
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          {/* MENU MOVIMENTI */}

          {/* MENU RICETTE */}

          <Menu as="div" className="ml-3 relative">
            <Menu.Button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400 w-full">
              Ricette
              <ChevronDownIcon
                className="-mr-1 ml-auto md:ml-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="ricette"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Vedi Ricette
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="ricette/nuova"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Nuova Ricetta
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          {/* MENU RICETTE */}

          {/* MENU ALTRO */}
          <Menu as="div" className="ml-3 relative">
            <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400 w-full">
              Altro
              <ChevronDownIcon
                className="-mr-1 ml-auto md:ml-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="risorse"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Risorse Impianto
                    </Link>
                  )}
                </Menu.Item>
                {user?.role === 104 ? (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="edita"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Edita Impianto
                      </Link>
                    )}
                  </Menu.Item>
                ) : undefined}
              </Menu.Items>
            </Transition>
          </Menu>
          {/* MENU ALTRO */}
        </div>
      </div>

      <div className="mx-auto mt-8  max-w-3xl  sm:px-6 lg:max-w-7xl ">
        <div className="sm:w-60">
          <Select
            items={maxMovimentiOptions}
            selectedItem={maxMovimenti}
            setSelectedItem={setMaxMovimenti}
            label={"Ultimi Movimenti Visualizzati"}
          />
        </div>
        <div className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3 mt-4">
          <section
            aria-labelledby="ultimi-movimenti"
            className="space-y-6 lg:col-span-2 lg:col-start-1"
          >
            <UltimiMovimenti maxItems={parseInt(maxMovimenti.name)} />
          </section>

          <section className="gap-6 space-y-6 lg:col-start-3">
            {isLoading ? (
              <RicetteRisorseSkeleton />
            ) : (
              <>
                {/* RICETTE LIST <RicetteList ricette={impianto?.ricette} /> */}

                {/* RISORSE LIST */}
                <RisorseList risorse={impianto?.risorse} />
              </>
            )}
          </section>
        </div>
      </div>
      {/* Stacked list */}
    </>
  );

  return content;
};

export default Impianto;
