import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectError,
  selectPersist,
  setCredentials,
  setError,
  setPersistOff,
  setPersistOn,
} from "./authSlice";
import { useLoginMutation } from "./authApiSlice";

import Input from "../../components/forms/Input";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import LoadingSpinner from "../../components/LoadingScreen/LoadingSpinner";

const Login = () => {
  const userRef = useRef(null);

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const persist = useSelector(selectPersist);
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const error = useSelector(selectError);

  const canSave = [user, pwd].every(Boolean);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!canSave) {
      dispatch(setError("Tutti i campi sono obbligatori"));
      return;
    }

    try {
      const userData = await login({ username: user, password: pwd }).unwrap();
      const expiration = Date.now() + userData.expires_in * 1000;

      dispatch(setCredentials({ ...userData, tokenExpires: expiration }));
      setUser("");
      setPwd("");
      dispatch(setError(null));

      setTimeout(() => navigate(from, { replace: true }), 0); // ✅ Naviga dopo aver aggiornato Redux
    } catch (err) {
      if (!err?.status) {
        dispatch(setError("Nessuna risposta dal server"));
      } else if (err.status === 400) {
        dispatch(setError("Tutti i campi sono obbligatori"));
      } else if (err.status === 403) {
        dispatch(setError("Nome utente o password errati"));
      } else {
        dispatch(setError("Tentativo di login fallito"));
      }
    }
  };

  const handleUserInput = (e) => {
    if (error) dispatch(setError(null)); // ✅ Resetta l'errore se l'utente digita
    setUser(e.target.value);
  };
  const handlePwdInput = (e) => {
    if (error) dispatch(setError(null)); // ✅ Resetta l'errore se l'utente digita
    setPwd(e.target.value);
  };
  const handleTogglePersist = () => {
    const newPersist = !persist;
    dispatch(newPersist ? setPersistOn() : setPersistOff());
    localStorage.setItem("persist", newPersist.toString()); // ✅ Aggiorna immediatamente
  };

  return isLoading ? (
    <div className="flex flex-col justify-center items-center h-40">
      <LoadingSpinner />
    </div>
  ) : (
    <section>
      {error && <ErrorAlert title={error} />}

      <form className="space-y-6" onSubmit={handleSubmit}>
        <Input
          value={user}
          setValue={handleUserInput}
          label="Nome Utente"
          name="username"
          autoComplete="off"
          ref={userRef}
          required={submitted}
        />

        <Input
          value={pwd}
          setValue={handlePwdInput}
          label="Password"
          name="password"
          autoComplete="current-password"
          type="password"
          required={submitted}
        />

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="persist"
              name="persist"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onChange={handleTogglePersist}
              checked={persist}
            />
            <label
              htmlFor="persist"
              className="ml-2 block text-sm text-gray-900"
            >
              Ricordati di me
            </label>
          </div>
        </div>

        <div className="flex items-center justify-end">
          <a
            href="#"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Dimenticato la password?
          </a>
        </div>

        <PrimaryButton type="submit" className="w-full">
          Sign in
        </PrimaryButton>
      </form>
    </section>
  );
};

export default Login;
