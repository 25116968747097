import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../features/auth/authApiSlice";
import { logOut, setError } from "../features/auth/authSlice";
import useAuth from "./useAuth";

const useLogout = () => {
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const { logOut: resetLocalSession } = useAuth();

  return async () => {
    try {
      await logout().unwrap(); // ✅ Usa unwrap() per gestire errori
      resetLocalSession();
    } catch (error) {
      console.error("Errore durante il logout:", error);
      dispatch(setError(error?.data?.message || "Errore di logout")); // ✅ Mostra errore se API fallisce
    } finally {
      localStorage.removeItem("persist"); // ✅ Resetta persistenza
      dispatch(logOut()); // ✅ Esegue logout locale
    }
  };
};

export default useLogout;
