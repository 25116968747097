import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const apiRoute = "https://api.casaluccisrl.com/api/v1";
const devApiRoute = "http://localhost:8000/api/v1";

const usePresignedUrl = (filePath) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPresignedUrl = useCallback(async () => {
    if (!filePath) return;

    setLoading(true);
    setError(null);

    try {
      const api =
        process.env.NODE_ENV === "development" ? devApiRoute : apiRoute;
      const response = await axios.get(`${api}/get-file-url/${filePath}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setUrl(response?.data?.url ?? null);
    } catch (err) {
      setError(err.response?.data?.message || "Errore nel recupero del link");
    } finally {
      setLoading(false);
    }
  }, [filePath]);

  useEffect(() => {
    fetchPresignedUrl();
  }, [fetchPresignedUrl]);

  return { url, loading, error, refresh: fetchPresignedUrl };
};

export default usePresignedUrl;
