import {
  CalendarIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { DocumentArrowDownIcon, TruckIcon } from "@heroicons/react/24/solid";

import {
  convertDate,
  formatDateObjectToItaliana,
} from "../../../utilities/date";
import EliminaModal from "../components/EliminaModal";
import { getUsciteSelectors, useDeleteUscitaMutation } from "../usciteApiSlice";
import NotFound from "../components/NotFound";
import {
  createImageLink,
  createResourceLink,
} from "../../../utilities/generali";
import useNavigation from "../../../hooks/useNavigation";
import { selectImpiantoById } from "../impiantiApiSlice";
import DownloadButton from "../components/DownloadButton";

const UscitaPage = ({}) => {
  const { uscitaId, impiantoId } = useParams();

  const { selectUscitaById } = useMemo(
    () => getUsciteSelectors(Number(impiantoId)),
    [uscitaId]
  );
  const uscita = useSelector(selectUscitaById(Number(uscitaId)));

  const impianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  useNavigation([
    { name: impiantoId, newName: impianto.nome },
    { name: uscitaId, newName: uscita.numero },
  ]);

  const [deleteUscita] = useDeleteUscitaMutation();

  const [openDelete, setOpenDelete] = useState(false);
  const navigate = useNavigate();

  if (!uscita) return <NotFound />;

  const deleteAction = async () => {
    try {
      await deleteUscita({ id: Number(uscitaId) });
      navigate(`/${impiantoId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const modalProps = {
    movimento: uscita,
    openDelete,
    setOpenDelete,
    deleteAction,
  };

  let content = (
    <div className="flex-1 bg-white rounded-md py-6 px-10 max-w-6xl mx-auto">
      <div className="py-8 xl:py-10">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 xl:grid xl:max-w-full xl:grid-cols-3">
          <div className="xl:col-span-2 xl:border-r xl:border-gray-200 xl:pr-8">
            <div>
              <div>
                <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">
                      Resoconto Uscita
                    </h1>
                    <div className="flex space-x-1">
                      <p className="mt-2 text-sm text-gray-500">
                        DDT n°{" "}
                        <span className="font-medium text-gray-900">
                          {uscita?.numero}
                        </span>{" "}
                        del{" "}
                        <span className="font-medium text-gray-900">
                          {convertDate(uscita?.data)}
                        </span>
                      </p>
                      {Boolean(uscita?.pdf) && (
                        <DownloadButton path={uscita?.pdf} />
                      )}
                    </div>
                    {uscita?.cliente ? (
                      <p className="mt-2 text-sm text-gray-500">
                        Cliente:{" "}
                        <span className="font-medium text-gray-900">
                          {uscita?.cliente?.nome}
                        </span>
                      </p>
                    ) : (
                      <p className="mt-2 text-sm text-gray-500">
                        Nostro Cantiere:{" "}
                        <span className="font-medium text-gray-900">
                          {uscita?.cantiere?.nome}
                        </span>
                      </p>
                    )}
                    <p className="mt-2 text-sm text-gray-500">
                      Destinazione:{" "}
                      <span className="font-medium text-gray-900">
                        {uscita?.destinazione}
                      </span>
                    </p>
                  </div>
                  <div className="mt-4 flex space-x-3 md:mt-0">
                    <Link
                      to="edita"
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
                    >
                      <PencilIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Edita</span>
                    </Link>
                    <button
                      role="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
                      onClick={() => setOpenDelete(true)}
                    >
                      <TrashIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Elimina</span>
                    </button>
                  </div>
                </div>
                <aside className="mt-8 xl:hidden">
                  <h2 className="sr-only">Dettagli</h2>
                  <div className="space-y-5">
                    <div className="flex items-center space-x-2">
                      <TruckIcon
                        className="h-5 w-5 text-casalucci"
                        aria-hidden="true"
                      />
                      <span className="text-sm font-medium text-casalucci-600">
                        Movimento
                      </span>
                    </div>

                    <div className="flex items-center space-x-2">
                      <CalendarIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-700 font-medium">
                        Creato in data{" "}
                        <time dateTime={uscita?.created_at}>
                          {formatDateObjectToItaliana(uscita?.created_at)}
                        </time>
                      </span>
                    </div>
                  </div>
                  <div className="mt-6 space-y-8 border-t border-b border-gray-200 py-6">
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Creato Da
                      </h2>
                      <ul role="list" className="mt-3 space-y-3">
                        <li className="flex justify-start">
                          <a href="#" className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-5 w-5 rounded-full"
                                src={createImageLink(uscita?.created_by?.foto)}
                                alt="immagine profilo"
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              {uscita?.created_by?.nome +
                                " " +
                                uscita?.created_by?.cognome}
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {uscita?.updated_by ? (
                      <div>
                        <h2 className="text-sm font-medium text-gray-500">
                          Modificato da
                        </h2>
                        <ul role="list" className="mt-3 space-y-3">
                          <li className="flex justify-start">
                            <a href="#" className="flex items-center space-x-3">
                              <div className="flex-shrink-0">
                                <img
                                  className="h-5 w-5 rounded-full"
                                  src={createImageLink(
                                    uscita?.updated_by?.foto
                                  )}
                                  alt=""
                                />
                              </div>
                              <div className="text-sm font-medium text-gray-900">
                                {uscita?.updated_by?.nome +
                                  " " +
                                  uscita?.updated_by?.cognome}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    ) : undefined}

                    <div></div>
                  </div>
                </aside>
                <div className="py-3 xl:pb-0">
                  <div className="divide-gray-200 divide-y">
                    <div className="py-6">
                      <p className="font text-gray-900 font-semibold">
                        {uscita?.ricetta?.nome}
                      </p>
                      <div className="sm:flex sm:flex-row sm:gap-4 text-sm text-gray-700 font-light justify-between mt-2">
                        Quantità:{" "}
                        <span className="text-black font-normal ml-2">
                          {uscita?.qta}{" "}
                          {uscita?.ricetta?.um !== "cad" && uscita?.ricetta?.um}
                        </span>
                      </div>
                    </div>
                    <div className="py-6">
                      <p>Ingredienti:</p>
                      <ol className="mt-2 text-sm text-gray-700 font-light">
                        {uscita?.risorse.map((ingrediente) => (
                          <li
                            key={ingrediente.id}
                            className="flex items-center mt-2"
                          >
                            <span className="flex space-x-3 items-center">
                              {ingrediente.foto ? (
                                <img
                                  className="h-6 w-6 flex-shrink-0 rounded-full bg-gray-100"
                                  src={createImageLink(ingrediente.foto)}
                                  alt=""
                                />
                              ) : (
                                <span className="h-6 w-6 rounded-full bg-gray-100" />
                              )}

                              <span>{ingrediente.nome}</span>
                            </span>

                            <span className="ml-auto">
                              {ingrediente.qta}{" "}
                              {ingrediente.um !== "cad" && ingrediente.um}
                            </span>
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center md:justify-end md:space-x-4 xl:border-t xl:pt-6">
                  <div>
                    {uscita.cantiere ? (
                      <>
                        Totale: <span>€ {uscita?.costo}</span>
                      </>
                    ) : (
                      <>
                        Totale: <span>€ {uscita?.importo}</span>{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside className="hidden xl:block xl:pl-8">
            <h2 className="sr-only">Dettagli</h2>
            <div className="space-y-5">
              <div className="flex items-center space-x-2">
                <TruckIcon
                  className="h-5 w-5 text-casalucci"
                  aria-hidden="true"
                />
                <span className="text-sm font-medium text-casalucci-600">
                  Movimento
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <CalendarIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-700 font-medium">
                  Creato in data{" "}
                  <time dateTime={uscita?.created_at}>
                    {formatDateObjectToItaliana(uscita?.created_at)}
                  </time>
                </span>
              </div>
            </div>
            <div className="mt-6 space-y-8 border-t border-gray-200 py-6">
              <div>
                <h2 className="text-sm font-medium text-gray-500">Creato da</h2>
                <ul role="list" className="mt-3 space-y-3">
                  <li className="flex justify-start">
                    <a href="#" className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-5 w-5 rounded-full"
                          src={createImageLink(uscita?.created_by?.foto)}
                          alt=""
                        />
                      </div>
                      <div className="text-sm font-medium text-gray-900">
                        {uscita?.created_by?.nome +
                          " " +
                          uscita?.created_by?.cognome}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              {uscita?.updated_by ? (
                <div>
                  <h2 className="text-sm font-medium text-gray-500">
                    Modificato da
                  </h2>
                  <ul role="list" className="mt-3 space-y-3">
                    <li className="flex justify-start">
                      <a href="#" className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          <img
                            className="h-5 w-5 rounded-full"
                            src={createImageLink(uscita?.updated_by?.foto)}
                            alt=""
                          />
                        </div>
                        <div className="text-sm font-medium text-gray-900">
                          {uscita?.updated_by?.nome +
                            " " +
                            uscita?.updated_by?.cognome}
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              ) : undefined}
            </div>
          </aside>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <EliminaModal {...modalProps} />
      {content}
    </>
  );
};

export default UscitaPage;
