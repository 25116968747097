import { Link } from "react-router-dom";
import { MapPinIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";
import {
  ArchiveBoxArrowDownIcon,
  ArrowUpOnSquareIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { createImageLink } from "../../utilities/generali";

const ImpiantiList = ({ impianti }) => {
  let content;

  content = (
    <div className="mt-8 flex flex-col">
      <div className="bg-white shadow overflow-hidden sm:rounded-md dark:bg-slate-900">
        <ul role="list" className="divide-y divide-gray-200">
          {impianti.map((impianto) => (
            <li key={impianto.id}>
              <Link to={`${impianto.id}`} className="block hover:bg-gray-50">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-indigo-600 truncate">
                      {impianto.nome}
                    </p>
                    <div className="ml-2 flex-shrink-0 flex">
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
                        {impianto.tipo}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <Cog6ToothIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {impianto.descrizione}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <MapPinIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {impianto.indirizzo}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  content = (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-8"
    >
      {impianti.map((impianto) => (
        <li
          key={impianto.id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow focus-within:ring-2 focus-within:ring-casalucci-400 focus-within:ring-offset-2 dark:bg-slate-900"
        >
          <Link
            to={`${impianto.id}`}
            className="flex w-full items-center justify-between space-x-6 p-6 hover:opacity-90 group"
          >
            {impianto.foto ? (
              <img
                className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                src={createImageLink(impianto.foto)}
                alt=""
              />
            ) : (
              <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" />
            )}

            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3 justify-between">
                <h3 className="truncate text-sm font-medium text-gray-900 dark:text-white">
                  {impianto.nome}
                </h3>
                <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                  {impianto.tipo}
                </span>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400 hidden group-hover:block"
                  aria-hidden="true"
                />
              </div>
            </div>
          </Link>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <Link
                  to={`${impianto.id}/entrate`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                >
                  <ArchiveBoxArrowDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Entrate</span>
                </Link>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <Link
                  to={`${impianto.id}/uscite`}
                  className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                >
                  <ArrowUpOnSquareIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Uscite</span>
                </Link>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  return content;
};

export default ImpiantiList;
