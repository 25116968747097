import { CurrencyEuroIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

import { useSelector } from "react-redux";
import { selectAllRisorse, useGetRisorseQuery } from "./risorseApiSlice";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import RisorsaSlide from "./RisorsaSlide";
import Pagination from "./Pagination";
import Input from "../../components/forms/Input";
import {
  classNames,
  createImageLink,
  filterArray,
  filterArrayByCategory,
} from "../../utilities/generali";
import { Listbox, Transition } from "@headlessui/react";
import { CATEGORIE_RISORSA } from "./constants";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import usePagination from "../../hooks/usePagination";

const RisorseList = ({ isGrid }) => {
  const { isLoading } = useGetRisorseQuery();
  const risorse = useSelector(selectAllRisorse);

  const [openSlide, setOpenSlide] = useState(false);
  const [currentRisorsa, setCurrentRisorsa] = useState(risorse[0] ?? {});

  const [searchTerm, setSearchTerm] = useState("");

  const [selectedCategories, setSelectedCategories] =
    useState(CATEGORIE_RISORSA);

  const risorseToDisplay = useMemo(() => {
    ///console.log("sdkjksnsdnvsdnsnjk", searchTerm, selectedCategories);
    return filterArrayByCategory(
      filterArray(risorse, searchTerm, ["nome", "categoria"]),
      selectedCategories
    );
  }, [searchTerm, risorse, selectedCategories]);

  const handleSelectRisorsa = (risorsa) => {
    setCurrentRisorsa(risorsa);
    setOpenSlide(true);
  };

  const handleSearchTermChange = (e) =>
    setSearchTerm(e.target.value.toLowerCase());

  const [pagination, currentPage, maxItems, selectMaxItems] =
    usePagination(risorseToDisplay);

  let content;

  content = (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sm:hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Risorsa
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Nome
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Costo
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Categoria
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {risorseToDisplay.map(
                  (risorsa, index) =>
                    index >= (currentPage - 1) * maxItems &&
                    index < currentPage * maxItems && (
                      <tr
                        key={risorsa?.id}
                        className={classNames(
                          index % 2 === 0 ? undefined : "bg-gray-50",
                          "hover:bg-casalucci-400 hover:bg-opacity-20"
                        )}
                      >
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 align-middle">
                          <div className="flex items-center">
                            {Boolean(risorsa.foto) ? (
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={createImageLink(risorsa.foto)}
                                  alt=""
                                />
                              </div>
                            ) : (
                              <div className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-casalucci-300">
                                <span className="text-xl font-medium leading-none text-white">
                                  {risorsa?.nome?.slice(0, 1)}
                                </span>
                              </div>
                            )}

                            <dl className="font-normal ml-4">
                              <dd className="font-medium text-gray-900">
                                {risorsa?.nome}
                              </dd>
                              <dd className="mt-1 whitespace-nowrap text-ellipsis overflow-hidden max-w-lg text-gray-700">
                                {risorsa?.note}
                              </dd>
                              <dd className="sm:hidden mt-1 truncate text-gray-500 flex items-center">
                                <CurrencyEuroIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                {risorsa?.costo}
                              </dd>
                              <dd className="sm:hidden mt-1 truncate text-gray-500">
                                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-casalucci-100 text-casalucci-600">
                                  {risorsa?.categoria}
                                </p>
                              </dd>
                            </dl>
                          </div>
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500 align-middle">
                          <div className="flex items-center">
                            <CurrencyEuroIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {risorsa?.costo}
                          </div>
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500 align-middle">
                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-casalucci-100 text-casalucci-600">
                            {risorsa?.categoria}
                          </p>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 align-middle">
                          <a
                            onClick={() => handleSelectRisorsa(risorsa)}
                            className="text-casalucci hover:text-casalucci-800 cursor-pointer"
                          >
                            Vedi
                          </a>
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  /*  OLD GRID


  <div className="flex flex-col">
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {risorseToDisplay.map(
            (risorsa, index) =>
              index >= (currentPage - 1) * 12 &&
              index < currentPage * 12 && (
                <li key={risorsa.id}>
                  <a
                    onClick={() => handleSelectRisorsa(risorsa)}
                    className="block hover:bg-gray-50 cursor-pointer"
                  >
                    <div className="px-4 py-2 sm:px-6">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-indigo-600 truncate">
                          {risorsa.nome}
                        </p>
                        <div className="ml-2 flex-shrink-0 flex">
                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {risorsa?.categoria}
                          </p>
                        </div>
                      </div>
                      <div className="flex-shrink-0 flex">
                        <p className="inline-flex text-xs leading-5 text-gray-600 truncate">
                          {risorsa.note}
                        </p>
                      </div>
                      <div className="mt-2 sm:flex">
                        <div className="sm:flex">
                          <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <CurrencyEuroIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {risorsa.costo}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              )
          )}
        </ul>
      </div>
    </div>

   */

  if (isGrid) {
    content = (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        {risorseToDisplay.map(
          (risorsa, index) =>
            index >= (currentPage - 1) * maxItems &&
            index < currentPage * maxItems && (
              <div
                key={risorsa?.id}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <div className="flex-shrink-0">
                  {risorsa.foto ? (
                    <img
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                      src={createImageLink(risorsa.foto)}
                      alt=""
                    />
                  ) : (
                    <div className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-casalucci-300">
                      <span className="text-xl font-medium leading-none text-white">
                        {risorsa?.nome?.slice(0, 1)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <a
                    onClick={() => handleSelectRisorsa(risorsa)}
                    className="focus:outline-none cursor-pointer"
                  >
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">
                      {risorsa?.nome}
                    </p>
                    <p className="text-xs text-gray-600 truncate">
                      {risorsa?.note}
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <CurrencyEuroIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {risorsa?.costo}
                    </p>
                  </a>
                </div>
                <div className="ml-2 flex-shrink-0 flex">
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-casalucci-100 text-casalucci-700">
                    {risorsa?.categoria}
                  </p>
                </div>
              </div>
            )
        )}
      </div>
    );
  }

  let slideOver = (
    <RisorsaSlide
      risorsa={currentRisorsa}
      open={openSlide}
      setOpen={setOpenSlide}
    />
  );

  let searchBox = (
    <div className="mt-2 sm:mt-auto">
      <Input
        className="sm:max-w-min"
        placeholder="Cerca"
        value={searchTerm}
        setValue={handleSearchTermChange}
        name="searchTerm"
      />
    </div>
  );

  {
    /* BEGIN SELECT */
  }
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef();
  useOnClickOutside(selectRef, () => setIsOpen(false));

  function isSelected(value) {
    return !!selectedCategories.find((el) => el === value);
  }

  function handleSelect(value) {
    if (!isSelected(value)) {
      const selectedCategoriesUpdated = [
        ...selectedCategories,
        CATEGORIE_RISORSA.find((el) => el === value),
      ];
      setSelectedCategories(selectedCategoriesUpdated);
    } else {
      handleDeselect(value);
    }
    setIsOpen(true);
  }

  function handleDeselect(value) {
    const selectedCategoriesUpdated = selectedCategories.filter(
      (el) => el !== value
    );
    setSelectedCategories(selectedCategoriesUpdated);
    setIsOpen(true);
  }

  let filter = (
    <div className="max-w-xs w-full mt-auto">
      <Listbox
        as="div"
        className="space-y-1"
        value={selectedCategories}
        onChange={(value) => handleSelect(value)}
        open={isOpen}
        ref={selectRef}
      >
        {() => (
          <>
            <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700">
              Filtra per Categoria
            </Listbox.Label>
            <div className="mt-1 relative">
              <span className="inline-block w-full rounded-md shadow-sm">
                <Listbox.Button
                  onClick={() => setIsOpen(!isOpen)}
                  open={isOpen}
                  className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-casalucci-400 focus:border-casalucci-400 sm:text-sm"
                >
                  <span className="block truncate">
                    {selectedCategories.length < 1
                      ? "Categorie"
                      : `Categorie (${selectedCategories.length})`}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
              </span>

              <Transition
                show={isOpen}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {CATEGORIE_RISORSA.sort().map((category) => {
                    const selected = isSelected(category);
                    return (
                      <Listbox.Option key={category} value={category}>
                        {({ active }) => (
                          <div
                            className={`${
                              active
                                ? "text-white bg-casalucci"
                                : "text-gray-900"
                            } cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-casalucci bg-opacity-60`}
                          >
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {category}
                            </span>

                            <span
                              className={classNames(
                                active ? "text-white" : "text-casalucci",
                                "absolute inset-y-0 left-0 flex items-center pl-2"
                              )}
                            >
                              <input
                                checked={selected}
                                onChange={() => null}
                                type="checkbox"
                                className="focus:ring-casalucci-400 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                            </span>
                          </div>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );

  return (
    <div className="h-full">
      <div className="sm:flex sm:justify-between mb-4">
        {filter}
        {searchBox}
      </div>

      {!isLoading && content}

      {!isLoading && pagination}
      <div className="flex">
        <div>{selectMaxItems}</div>
      </div>

      {!isLoading && slideOver}
    </div>
  );
};

export default RisorseList;
