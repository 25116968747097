import axios from "axios";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const filterArray = function filterArrayOfObjectsByArrayOfProps(
  array,
  string = "",
  props = ["id"]
) {
  return array.filter((item) =>
    props.some((key) => item[key]?.toLowerCase().includes(string))
  );
};

export const filterArrayByCategory = function (data, category) {
  return data.filter((item) =>
    category.some((value) => item.categoria && item.categoria.includes(value))
  );
};

export const createImageLink = (link) => {
  return `https://api.casaluccisrl.com/api/v1/assets/${link}`;
};

export const createResourceLink = (link) => {
  return `https://api.casaluccisrl.com/api/v1/assets/${link}`;
};

export function formatoNumeriGrandi(x) {
  if (!x) return;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatDecimalNumber(number) {
  return (Math.round(number * 100) / 100).toFixed(2);
}

export const removeDuplicates = function removeDuplicatesFromArrayOfObjects(
  array,
  props = ["id"]
) {
  let filtered = [];
  props.forEach((prop) => {
    filtered = array.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t[prop] === value[prop])
    );
  });

  return filtered;
};

export const sortByProp = function sortArrayOfObjectsByProp(
  array,
  prop = "nome"
) {
  function compare(a, b) {
    if (a[prop] < b[prop]) {
      return -1;
    }
    if (a[prop] > b[prop]) {
      return 1;
    }
    return 0;
  }

  return array.sort(compare);
};
