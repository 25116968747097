import { setCredentials, setError, logOut } from "../features/auth/authSlice";
import { useRefreshMutation } from "../features/auth/authApiSlice";
import { useDispatch } from "react-redux";

const useRefreshToken = () => {
  const [refresh] = useRefreshMutation();
  const dispatch = useDispatch();

  return async () => {
    try {
      const response = await refresh().unwrap();

      if (!response?.access_token || !response?.expires_in) {
        throw new Error("Invalid token response");
      }

      const expiration = Date.now() + response.expires_in * 1000;

      dispatch(
        setCredentials({
          role: Array.isArray(response?.user.role)
            ? response?.user.role
            : [response?.user.role],
          access_token: response.access_token,
          tokenExpires: expiration,
          user: response?.user,
        })
      );

      return response.access_token;
    } catch (error) {
      console.error("Errore nel refresh del token:", error);
      dispatch(setError(error?.data?.message || "Sessione scaduta"));
      dispatch(logOut()); // ✅ Se il refresh fallisce, fai il logout
    }
  };
};

export default useRefreshToken;
