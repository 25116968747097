import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    error: null,
    tokenExpires: null,
    persist: JSON.parse(localStorage.getItem("persist")) || false,
    roles: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, access_token, tokenExpires } = action.payload;
      state.user = user;
      state.token = access_token;
      state.roles = user.role;
      state.tokenExpires = tokenExpires;
    },
    setNewToken: (state, action) => {
      const { access_token, tokenExpires } = action.payload;
      state.token = access_token;
      if (tokenExpires) state.tokenExpires = tokenExpires; // ✅ Aggiorna se disponibile
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.roles = null; // ✅ Pulisce i ruoli
      state.tokenExpires = null; // ✅ Evita dati residui
      localStorage.setItem("persist", "false");
      state.persist = false;
    },
    setPersistOn: (state) => {
      state.persist = true;
    },
    setPersistOff: (state) => {
      state.persist = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    removeError: (state, action) => {
      state.error = null;
    },
  },
});

export const {
  setCredentials,
  logOut,
  setPersistOn,
  setPersistOff,
  setNewToken,
  setError,
  removeError,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectAuth = (state) => state.auth;
export const selectError = (state) => state.auth.error;
export const selectPersist = (state) => state.auth.persist;
