import { useSelector, useDispatch } from "react-redux";
import { selectAuth, selectPersist, logOut } from "../features/auth/authSlice";

const useAuth = () => {
  const dispatch = useDispatch();

  return {
    auth: useSelector(selectAuth),
    persist: useSelector(selectPersist),
    logOut: () => dispatch(logOut()),
  };
};

export default useAuth;
