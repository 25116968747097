import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { utils, writeFile } from "xlsx";

import {
  CurrencyEuroIcon,
  DocumentChartBarIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import WhiteButton from "../../../components/buttons/WhiteButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

const ResocontoMovimentiModal = ({ movimenti = [], open, setOpen }) => {
  let ricette = movimenti
    .map((movimento) => movimento.ricetta)
    .filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

  ricette = ricette.map((ricetta) => {
    let qta = movimenti
      .filter((m) => m.ricetta.id === ricetta.id)
      .map((m) => Number(m.qta))
      .reduce((partialSum, a) => partialSum + a, 0);

    let importo = movimenti
      .filter((m) => m.ricetta.id === ricetta.id)
      .map((m) => Number(m.importo))
      .reduce((partialSum, a) => partialSum + a, 0);

    return {
      ...ricetta,
      qta,
      importo,
    };
  });

  const exportExcel = () => {
    const rowsMovimenti = movimenti
      .map((movimento) => ({
        numero: movimento.numero,
        data: movimento.data,
        destinazione: movimento.destinazione,
        prodotto: movimento.ricetta.nome,
        um: movimento.ricetta.um,
        qta: movimento.qta,
        unitario: movimento.importoUnitario,
        totale: movimento.importo,
      }))
      .sort((a, b) => {
        // Estrai le parti con una regex
        const regex = /^([A-Z]+\d*)-(\d+)\/(\d+)$/;

        const [, categoryA, yearA, monthA] = a.numero.match(regex);
        const [, categoryB, yearB, monthB] = b.numero.match(regex);

        console.log(monthA, yearA, categoryA);

        // Ordina per categoria (alfabetico)
        if (categoryA !== categoryB) return categoryA.localeCompare(categoryB);
        // Ordina per anno (numerico)
        if (yearA !== yearB) return Number(yearA) - Number(yearB);
        // Ordina per mese (numerico)
        return Number(monthA) - Number(monthB);
      });

    console.log(rowsMovimenti);

    const worksheetMovimenti = utils.json_to_sheet(rowsMovimenti);

    const rowsRicette = ricette.map((ricetta) => ({
      prodotto: ricetta.nome,
      um: ricetta.um,
      qta: ricetta.qta,
      importo: ricetta.importo,
    }));

    const worksheetRicette = utils.json_to_sheet(rowsRicette);

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheetMovimenti, "DDT");
    utils.book_append_sheet(workbook, worksheetRicette, "Resoconto");

    writeFile(workbook, "ResocontoMovimenti.xlsx", { compression: true });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-casalucci-100 sm:mx-0 sm:h-10 sm:w-10">
                    <DocumentChartBarIcon
                      className="h-6 w-6 text-casalucci"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Resoconto dei movimenti visualizzati
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        La tabella sottostante include un resoconto dei
                        movimenti visualizzati raggruppati per ricetta, con la
                        quantità e l'importo di vendita totale. Cliccando su
                        "Esporta Excel" viene generato un foglio excel con i
                        dati della tabella e di tutti i DDT relativi.
                      </p>
                    </div>
                  </div>
                </div>
                {/* TABELLA */}
                <div className=" ml-3 mt-4 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                              Ricetta
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              UM
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Quantità
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Totale
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {ricette.map((ricetta) => (
                            <tr key={ricetta.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                {ricetta.nome}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {ricetta.um}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {Number(ricetta.qta).toFixed(2)}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                  <CurrencyEuroIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="truncate">
                                    {Number(ricetta.importo).toFixed(2)}
                                  </span>
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* TABELLA */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-x-2">
                  <PrimaryButton
                    callback={() => exportExcel()}
                    className="w-full md:w-auto"
                  >
                    Esporta Excel
                  </PrimaryButton>
                  <WhiteButton
                    callback={() => setOpen(false)}
                    className="w-full md:w-auto"
                  >
                    Chiudi
                  </WhiteButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ResocontoMovimentiModal;
