import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import clsx from "clsx";

const Pagination = ({
  itemsNumber,
  currentPage,
  setCurrentPage,
  maxItems = 5,
}) => {
  let pagesNumber = Math.ceil(itemsNumber / maxItems);
  let allPages = Array.from({ length: pagesNumber }, (_, i) => i + 1);
  let pageOptions = [...allPages.slice(0, 3), ...allPages.slice(-3)];

  pageOptions = [...new Set(pageOptions)];

  const handlePageChange = (value) => {
    if (currentPage === 1 && value === -1) return;
    if (currentPage === pagesNumber && value === 1) return;
    setCurrentPage((state) => state + value);
  };

  return (
    <div className="md:flex">
      <nav className="mx-auto mt-4 flex items-center justify-between space-x-10 border-t border-gray-200 px-4 sm:px-0">
        <div className="-mt-px flex flex-1 cursor-pointer">
          <a
            onClick={() => handlePageChange(-1)}
            className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Prev
          </a>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {pageOptions.map((page, index, array) => (
            <Fragment key={page}>
              {index === 3 &&
                (array.includes(currentPage) ? (
                  <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                    ...
                  </span>
                ) : (
                  <>
                    {currentPage !== 4 && (
                      <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                        ...
                      </span>
                    )}

                    <button
                      onClick={() => setCurrentPage(page)}
                      className={
                        "inline-flex cursor-pointer items-center border-t-2 border-casalucci-400 px-4 pt-4 text-sm font-medium text-casalucci"
                      }
                    >
                      {currentPage}
                    </button>
                    {currentPage !== pagesNumber - 3 && (
                      <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                        ...
                      </span>
                    )}
                  </>
                ))}
              <button
                onClick={() => setCurrentPage(page)}
                className={clsx(
                  "inline-flex cursor-pointer items-center border-t-2 px-4 pt-4 text-sm font-medium",
                  page === currentPage
                    ? "border-casalucci-400 text-casalucci"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                )}
              >
                {page}
              </button>
            </Fragment>
          ))}
        </div>
        <span
          className={
            "inline-flex cursor-pointer items-center border-t-2 border-casalucci-400 px-4 pt-4 text-sm font-medium text-casalucci md:hidden"
          }
        >
          {currentPage}
        </span>
        <div className="-mt-px flex flex-1 cursor-pointer justify-end">
          <a
            onClick={() => handlePageChange(1)}
            className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Pagination;
